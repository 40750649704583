import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a5b8b56 = () => interopDefault(import('../pages/accessibilite.vue' /* webpackChunkName: "pages/accessibilite" */))
const _529a9baf = () => interopDefault(import('../pages/bureau-etude-urbanisme.vue' /* webpackChunkName: "pages/bureau-etude-urbanisme" */))
const _25b04aa2 = () => interopDefault(import('../pages/collectivites-territoriales.vue' /* webpackChunkName: "pages/collectivites-territoriales" */))
const _1ba4c1a4 = () => interopDefault(import('../pages/confidentialite.vue' /* webpackChunkName: "pages/confidentialite" */))
const _4d7832bb = () => interopDefault(import('../pages/ddt-ddtm-dreal.vue' /* webpackChunkName: "pages/ddt-ddtm-dreal" */))
const _164d55db = () => interopDefault(import('../pages/exports.vue' /* webpackChunkName: "pages/exports" */))
const _170f3535 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _4dfa1818 = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _04fcfcc8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0c82a9c6 = () => interopDefault(import('../pages/loi-climat-et-resilience.vue' /* webpackChunkName: "pages/loi-climat-et-resilience" */))
const _48261592 = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _c6959176 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _ed4d7304 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _76d8c08d = () => interopDefault(import('../pages/validation.vue' /* webpackChunkName: "pages/validation" */))
const _569a633c = () => interopDefault(import('../pages/dev/api.vue' /* webpackChunkName: "pages/dev/api" */))
const _3d26eabd = () => interopDefault(import('../pages/stats/state.vue' /* webpackChunkName: "pages/stats/state" */))
const _1e2d75a0 = () => interopDefault(import('../pages/stats/urba.vue' /* webpackChunkName: "pages/stats/urba" */))
const _541c8b64 = () => interopDefault(import('../pages/login/collectivites/explain.vue' /* webpackChunkName: "pages/login/collectivites/explain" */))
const _d7c0b1ca = () => interopDefault(import('../pages/login/collectivites/signin.vue' /* webpackChunkName: "pages/login/collectivites/signin" */))
const _ae9404de = () => interopDefault(import('../pages/login/collectivites/signup.vue' /* webpackChunkName: "pages/login/collectivites/signup" */))
const _5867d252 = () => interopDefault(import('../pages/login/ddt/explain.vue' /* webpackChunkName: "pages/login/ddt/explain" */))
const _56447e97 = () => interopDefault(import('../pages/login/ddt/signin.vue' /* webpackChunkName: "pages/login/ddt/signin" */))
const _6adad50d = () => interopDefault(import('../pages/login/ddt/signup.vue' /* webpackChunkName: "pages/login/ddt/signup" */))
const _ccc7aa24 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0520437a = () => interopDefault(import('../pages/collectivites/_collectiviteId.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId" */))
const _3826c67d = () => interopDefault(import('../pages/collectivites/_collectiviteId/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/index" */))
const _911c435a = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees" */))
const _27b7e0d4 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/data.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/data" */))
const _112bff71 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geobretagne.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geobretagne" */))
const _c7a295b6 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geoide.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geoide" */))
const _68b7c02e = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/georisques.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/georisques" */))
const _1ab82070 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/gpu.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/gpu" */))
const _c5c27d62 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/inpn.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/inpn" */))
const _e11e9be0 = () => interopDefault(import('../pages/collectivites/_collectiviteId/glossaire.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/glossaire" */))
const _345356fd = () => interopDefault(import('../pages/collectivites/_collectiviteId/pac.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/pac" */))
const _6f256247 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/index" */))
const _01aa457d = () => interopDefault(import('../pages/collectivites/_collectiviteId/ressources.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/ressources" */))
const _36acf454 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/add" */))
const _2d2d9373 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/signup.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/signup" */))
const _768248f7 = () => interopDefault(import('../pages/collectivites/_collectiviteId/procedures/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/procedures/add" */))
const _0fa1d65f = () => interopDefault(import('../pages/ddt/_departement.vue' /* webpackChunkName: "pages/ddt/_departement" */))
const _3da5d9a2 = () => interopDefault(import('../pages/ddt/_departement/index.vue' /* webpackChunkName: "pages/ddt/_departement/index" */))
const _566ed87a = () => interopDefault(import('../pages/ddt/_departement/collectivites/index.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/index" */))
const _2c56ef62 = () => interopDefault(import('../pages/ddt/_departement/pac.vue' /* webpackChunkName: "pages/ddt/_departement/pac" */))
const _1eedd4e3 = () => interopDefault(import('../pages/ddt/_departement/procedures/index.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/index" */))
const _0039a31a = () => interopDefault(import('../pages/ddt/_departement/collectivites/enquete.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/enquete" */))
const _1620c496 = () => interopDefault(import('../pages/ddt/_departement/procedures/choix-collectivite.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/choix-collectivite" */))
const _3e391202 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/commune.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/commune" */))
const _92a6f07c = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/epci.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/epci" */))
const _72dd1844 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/procedure/add.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/procedure/add" */))
const _8847089c = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/dgd.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/dgd" */))
const _68d01694 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/infos.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/infos" */))
const _ea6b0558 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/perimetre.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/perimetre" */))
const _7b369046 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/volet_qualitatif.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/volet_qualitatif" */))
const _e30a6ca0 = () => interopDefault(import('../pages/faq/_folder.vue' /* webpackChunkName: "pages/faq/_folder" */))
const _e25bce74 = () => interopDefault(import('../pages/frise/_procedureId/index.vue' /* webpackChunkName: "pages/frise/_procedureId/index" */))
const _3156094b = () => interopDefault(import('../pages/print/_projectId.vue' /* webpackChunkName: "pages/print/_projectId" */))
const _dcda6338 = () => interopDefault(import('../pages/trames/_githubRef.vue' /* webpackChunkName: "pages/trames/_githubRef" */))
const _8392a802 = () => interopDefault(import('../pages/documents/_githubRef/pac.vue' /* webpackChunkName: "pages/documents/_githubRef/pac" */))
const _9e805fd6 = () => interopDefault(import('../pages/frise/_procedureId/add.vue' /* webpackChunkName: "pages/frise/_procedureId/add" */))
const _68f20a45 = () => interopDefault(import('../pages/frise/_procedureId/invite.vue' /* webpackChunkName: "pages/frise/_procedureId/invite" */))
const _1c90905c = () => interopDefault(import('../pages/frise/_procedureId/_eventId.vue' /* webpackChunkName: "pages/frise/_procedureId/_eventId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibilite",
    component: _1a5b8b56,
    name: "accessibilite"
  }, {
    path: "/bureau-etude-urbanisme",
    component: _529a9baf,
    name: "bureau-etude-urbanisme"
  }, {
    path: "/collectivites-territoriales",
    component: _25b04aa2,
    name: "collectivites-territoriales"
  }, {
    path: "/confidentialite",
    component: _1ba4c1a4,
    name: "confidentialite"
  }, {
    path: "/ddt-ddtm-dreal",
    component: _4d7832bb,
    name: "ddt-ddtm-dreal"
  }, {
    path: "/exports",
    component: _164d55db,
    name: "exports"
  }, {
    path: "/faq",
    component: _170f3535,
    name: "faq"
  }, {
    path: "/guide",
    component: _4dfa1818,
    name: "guide"
  }, {
    path: "/login",
    component: _04fcfcc8,
    name: "login"
  }, {
    path: "/loi-climat-et-resilience",
    component: _0c82a9c6,
    name: "loi-climat-et-resilience"
  }, {
    path: "/mentions-legales",
    component: _48261592,
    name: "mentions-legales"
  }, {
    path: "/profile",
    component: _c6959176,
    name: "profile"
  }, {
    path: "/stats",
    component: _ed4d7304,
    name: "stats"
  }, {
    path: "/validation",
    component: _76d8c08d,
    name: "validation"
  }, {
    path: "/dev/api",
    component: _569a633c,
    name: "dev-api"
  }, {
    path: "/stats/state",
    component: _3d26eabd,
    name: "stats-state"
  }, {
    path: "/stats/urba",
    component: _1e2d75a0,
    name: "stats-urba"
  }, {
    path: "/login/collectivites/explain",
    component: _541c8b64,
    name: "login-collectivites-explain"
  }, {
    path: "/login/collectivites/signin",
    component: _d7c0b1ca,
    name: "login-collectivites-signin"
  }, {
    path: "/login/collectivites/signup",
    component: _ae9404de,
    name: "login-collectivites-signup"
  }, {
    path: "/login/ddt/explain",
    component: _5867d252,
    name: "login-ddt-explain"
  }, {
    path: "/login/ddt/signin",
    component: _56447e97,
    name: "login-ddt-signin"
  }, {
    path: "/login/ddt/signup",
    component: _6adad50d,
    name: "login-ddt-signup"
  }, {
    path: "/",
    component: _ccc7aa24,
    name: "index"
  }, {
    path: "/collectivites/:collectiviteId?",
    component: _0520437a,
    children: [{
      path: "",
      component: _3826c67d,
      name: "collectivites-collectiviteId"
    }, {
      path: "donnees",
      component: _911c435a,
      name: "collectivites-collectiviteId-donnees",
      children: [{
        path: "data",
        component: _27b7e0d4,
        name: "collectivites-collectiviteId-donnees-data"
      }, {
        path: "geobretagne",
        component: _112bff71,
        name: "collectivites-collectiviteId-donnees-geobretagne"
      }, {
        path: "geoide",
        component: _c7a295b6,
        name: "collectivites-collectiviteId-donnees-geoide"
      }, {
        path: "georisques",
        component: _68b7c02e,
        name: "collectivites-collectiviteId-donnees-georisques"
      }, {
        path: "gpu",
        component: _1ab82070,
        name: "collectivites-collectiviteId-donnees-gpu"
      }, {
        path: "inpn",
        component: _c5c27d62,
        name: "collectivites-collectiviteId-donnees-inpn"
      }]
    }, {
      path: "glossaire",
      component: _e11e9be0,
      name: "collectivites-collectiviteId-glossaire"
    }, {
      path: "pac",
      component: _345356fd,
      name: "collectivites-collectiviteId-pac"
    }, {
      path: "prescriptions",
      component: _6f256247,
      name: "collectivites-collectiviteId-prescriptions"
    }, {
      path: "ressources",
      component: _01aa457d,
      name: "collectivites-collectiviteId-ressources"
    }, {
      path: "prescriptions/add",
      component: _36acf454,
      name: "collectivites-collectiviteId-prescriptions-add"
    }, {
      path: "prescriptions/signup",
      component: _2d2d9373,
      name: "collectivites-collectiviteId-prescriptions-signup"
    }, {
      path: "procedures/add",
      component: _768248f7,
      name: "collectivites-collectiviteId-procedures-add"
    }]
  }, {
    path: "/ddt/:departement?",
    component: _0fa1d65f,
    children: [{
      path: "",
      component: _3da5d9a2,
      name: "ddt-departement"
    }, {
      path: "collectivites",
      component: _566ed87a,
      name: "ddt-departement-collectivites"
    }, {
      path: "pac",
      component: _2c56ef62,
      name: "ddt-departement-pac"
    }, {
      path: "procedures",
      component: _1eedd4e3,
      name: "ddt-departement-procedures"
    }, {
      path: "collectivites/enquete",
      component: _0039a31a,
      name: "ddt-departement-collectivites-enquete"
    }, {
      path: "procedures/choix-collectivite",
      component: _1620c496,
      name: "ddt-departement-procedures-choix-collectivite"
    }, {
      path: "collectivites/:collectiviteId/commune",
      component: _3e391202,
      name: "ddt-departement-collectivites-collectiviteId-commune"
    }, {
      path: "collectivites/:collectiviteId/epci",
      component: _92a6f07c,
      name: "ddt-departement-collectivites-collectiviteId-epci"
    }, {
      path: "collectivites/:collectiviteId/procedure/add",
      component: _72dd1844,
      name: "ddt-departement-collectivites-collectiviteId-procedure-add"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/dgd",
      component: _8847089c,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-dgd"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/infos",
      component: _68d01694,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-infos"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/perimetre",
      component: _ea6b0558,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-perimetre"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/volet_qualitatif",
      component: _7b369046,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-volet_qualitatif"
    }]
  }, {
    path: "/faq/:folder",
    component: _e30a6ca0,
    name: "faq-folder"
  }, {
    path: "/frise/:procedureId",
    component: _e25bce74,
    name: "frise-procedureId"
  }, {
    path: "/print/:projectId?",
    component: _3156094b,
    name: "print-projectId"
  }, {
    path: "/trames/:githubRef?",
    component: _dcda6338,
    name: "trames-githubRef"
  }, {
    path: "/documents/:githubRef?/pac",
    component: _8392a802,
    name: "documents-githubRef-pac"
  }, {
    path: "/frise/:procedureId?/add",
    component: _9e805fd6,
    name: "frise-procedureId-add"
  }, {
    path: "/frise/:procedureId?/invite",
    component: _68f20a45,
    name: "frise-procedureId-invite"
  }, {
    path: "/frise/:procedureId?/:eventId",
    component: _1c90905c,
    name: "frise-procedureId-eventId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
