import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_6d21a0da from 'nuxt_plugin_plugin_6d21a0da' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_48a2e154 from 'nuxt_plugin_plugin_48a2e154' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_pluginclient_46592154 from 'nuxt_plugin_pluginclient_46592154' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_428ce0de from 'nuxt_plugin_pluginserver_428ce0de' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_workbox_5bf387e8 from 'nuxt_plugin_workbox_5bf387e8' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_2a98b71f from 'nuxt_plugin_metaplugin_2a98b71f' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_2f405c93 from 'nuxt_plugin_iconplugin_2f405c93' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_484a7a20 from 'nuxt_plugin_axios_484a7a20' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_dayjs_66ce5f68 from 'nuxt_plugin_dayjs_66ce5f68' // Source: ../plugins/dayjs.js (mode: 'all')
import nuxt_plugin_isDev_68a30dfc from 'nuxt_plugin_isDev_68a30dfc' // Source: ../plugins/isDev.js (mode: 'all')
import nuxt_plugin_composition_77f52d03 from 'nuxt_plugin_composition_77f52d03' // Source: ../plugins/composition.js (mode: 'all')
import nuxt_plugin_supabase_6fd7c51d from 'nuxt_plugin_supabase_6fd7c51d' // Source: ../plugins/supabase.js (mode: 'all')
import nuxt_plugin_supabaseAdmin_a273bdfc from 'nuxt_plugin_supabaseAdmin_a273bdfc' // Source: ../plugins/supabaseAdmin.js (mode: 'server')
import nuxt_plugin_utils_1ea7651c from 'nuxt_plugin_utils_1ea7651c' // Source: ../plugins/utils.js (mode: 'all')
import nuxt_plugin_user_43ed2bd4 from 'nuxt_plugin_user_43ed2bd4' // Source: ../plugins/user.js (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')
import nuxt_plugin_pac_46ccd9ca from 'nuxt_plugin_pac_46ccd9ca' // Source: ../plugins/pac.js (mode: 'all')
import nuxt_plugin_daturba_2c55b15a from 'nuxt_plugin_daturba_2c55b15a' // Source: ../plugins/daturba.js (mode: 'all')
import nuxt_plugin_matomo_ad92b6a0 from 'nuxt_plugin_matomo_ad92b6a0' // Source: ../plugins/matomo.js (mode: 'client')
import nuxt_plugin_mdParser_c52e7f2a from 'nuxt_plugin_mdParser_c52e7f2a' // Source: ../plugins/mdParser.js (mode: 'all')
import nuxt_plugin_print_a4dc9cc0 from 'nuxt_plugin_print_a4dc9cc0' // Source: ../plugins/print.js (mode: 'client')
import nuxt_plugin_rules_20a5ff16 from 'nuxt_plugin_rules_20a5ff16' // Source: ../plugins/rules.js (mode: 'all')
import nuxt_plugin_notifications_15a88b85 from 'nuxt_plugin_notifications_15a88b85' // Source: ../plugins/notifications.js (mode: 'client')
import nuxt_plugin_githubRefs_2b105ac4 from 'nuxt_plugin_githubRefs_2b105ac4' // Source: ../plugins/githubRefs.js (mode: 'all')
import nuxt_plugin_urbanisator_e6d6e54a from 'nuxt_plugin_urbanisator_e6d6e54a' // Source: ../plugins/urbanisator.js (mode: 'all')
import nuxt_plugin_pdfMake_4dc8d7ed from 'nuxt_plugin_pdfMake_4dc8d7ed' // Source: ../plugins/pdfMake.js (mode: 'client')
import nuxt_plugin_analytics_2e48c327 from 'nuxt_plugin_analytics_2e48c327' // Source: ../plugins/analytics.js (mode: 'all')
import nuxt_plugin_gtag_0a2cb1e4 from 'nuxt_plugin_gtag_0a2cb1e4' // Source: ../plugins/gtag.js (mode: 'client')
import nuxt_plugin_tally_625dd2fe from 'nuxt_plugin_tally_625dd2fe' // Source: ../plugins/tally.js (mode: 'client')
import nuxt_plugin_githubRefRoles_7c38ff34 from 'nuxt_plugin_githubRefRoles_7c38ff34' // Source: ../plugins/githubRefRoles.js (mode: 'client')
import nuxt_plugin_sharing_27698b91 from 'nuxt_plugin_sharing_27698b91' // Source: ../plugins/sharing.js (mode: 'all')
import nuxt_plugin_enquete_5b45b764 from 'nuxt_plugin_enquete_5b45b764' // Source: ../plugins/enquete.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s - Docurba","title":"Docurba","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"icon","type":"image\u002Fpng","href":"\u002Ffavicon.png"}],"script":[],"style":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_6d21a0da === 'function') {
    await nuxt_plugin_plugin_6d21a0da(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_48a2e154 === 'function') {
    await nuxt_plugin_plugin_48a2e154(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_46592154 === 'function') {
    await nuxt_plugin_pluginclient_46592154(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_428ce0de === 'function') {
    await nuxt_plugin_pluginserver_428ce0de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_5bf387e8 === 'function') {
    await nuxt_plugin_workbox_5bf387e8(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_2a98b71f === 'function') {
    await nuxt_plugin_metaplugin_2a98b71f(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_2f405c93 === 'function') {
    await nuxt_plugin_iconplugin_2f405c93(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_484a7a20 === 'function') {
    await nuxt_plugin_axios_484a7a20(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_66ce5f68 === 'function') {
    await nuxt_plugin_dayjs_66ce5f68(app.context, inject)
  }

  if (typeof nuxt_plugin_isDev_68a30dfc === 'function') {
    await nuxt_plugin_isDev_68a30dfc(app.context, inject)
  }

  if (typeof nuxt_plugin_composition_77f52d03 === 'function') {
    await nuxt_plugin_composition_77f52d03(app.context, inject)
  }

  if (typeof nuxt_plugin_supabase_6fd7c51d === 'function') {
    await nuxt_plugin_supabase_6fd7c51d(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_supabaseAdmin_a273bdfc === 'function') {
    await nuxt_plugin_supabaseAdmin_a273bdfc(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_1ea7651c === 'function') {
    await nuxt_plugin_utils_1ea7651c(app.context, inject)
  }

  if (typeof nuxt_plugin_user_43ed2bd4 === 'function') {
    await nuxt_plugin_user_43ed2bd4(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  if (typeof nuxt_plugin_pac_46ccd9ca === 'function') {
    await nuxt_plugin_pac_46ccd9ca(app.context, inject)
  }

  if (typeof nuxt_plugin_daturba_2c55b15a === 'function') {
    await nuxt_plugin_daturba_2c55b15a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_matomo_ad92b6a0 === 'function') {
    await nuxt_plugin_matomo_ad92b6a0(app.context, inject)
  }

  if (typeof nuxt_plugin_mdParser_c52e7f2a === 'function') {
    await nuxt_plugin_mdParser_c52e7f2a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_print_a4dc9cc0 === 'function') {
    await nuxt_plugin_print_a4dc9cc0(app.context, inject)
  }

  if (typeof nuxt_plugin_rules_20a5ff16 === 'function') {
    await nuxt_plugin_rules_20a5ff16(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_15a88b85 === 'function') {
    await nuxt_plugin_notifications_15a88b85(app.context, inject)
  }

  if (typeof nuxt_plugin_githubRefs_2b105ac4 === 'function') {
    await nuxt_plugin_githubRefs_2b105ac4(app.context, inject)
  }

  if (typeof nuxt_plugin_urbanisator_e6d6e54a === 'function') {
    await nuxt_plugin_urbanisator_e6d6e54a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pdfMake_4dc8d7ed === 'function') {
    await nuxt_plugin_pdfMake_4dc8d7ed(app.context, inject)
  }

  if (typeof nuxt_plugin_analytics_2e48c327 === 'function') {
    await nuxt_plugin_analytics_2e48c327(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtag_0a2cb1e4 === 'function') {
    await nuxt_plugin_gtag_0a2cb1e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tally_625dd2fe === 'function') {
    await nuxt_plugin_tally_625dd2fe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_githubRefRoles_7c38ff34 === 'function') {
    await nuxt_plugin_githubRefRoles_7c38ff34(app.context, inject)
  }

  if (typeof nuxt_plugin_sharing_27698b91 === 'function') {
    await nuxt_plugin_sharing_27698b91(app.context, inject)
  }

  if (typeof nuxt_plugin_enquete_5b45b764 === 'function') {
    await nuxt_plugin_enquete_5b45b764(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
